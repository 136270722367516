// function IsJson(json) {
//   let isJson = true;
//   try {
//     JSON.parse(json);
//   } catch (e) {
//     isJson = false;
//   }

//   return isJson;
// }

function JsonToArray(json) {
  // convert source (json) to an array of objects
  var dataArray = [];

  //check if the source is not null or undefined,  if it is not a valid json, then best to let it error
  if (json !== undefined && json !== null) {
    for (var key in json) {
      dataArray.push({ key: key, value: json[key] });
    }
  }

  return dataArray;
}

export { JsonToArray };
