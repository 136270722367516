import React, { useContext, useEffect, useState } from "react";

import { Accordion } from "./Accordion";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import axios from "axios";

import { UserTypes } from "../../constants/UserTypes";

export function NavBar(props) {
  const { auth } = props;
  const [userContactType, setUserContactType] = useState("");

  useEffect(() => {
    GetUserContactType();
  }, []);

  async function GetUserContactType() {
    const userInfo = await auth.getUser();
    const accessToken = await auth.getAccessToken();

    let userContractType = await axios.get(
      "api/UserProgram/GetUserContactType",
      {
        params: {
          email: userInfo.email,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    setUserContactType(userContractType.data);
  }

  return (
    <Accordion>
      <div icon="fa fa-dashboard" label=" Metric-Rx 2.0">
        <ul>
          <li key={"Analytics"}>
            <Link to="/Analytics">Dashboard</Link>
          </li>
          <li key={"LocationComparison"}>
            <Link to="/LocationComparison">Benchmark</Link>
          </li>
          {userContactType == "UCT" ? (
            <li key={"AugmentedAnalytics"}>
              <Link to="/AugmentedAnalytics">Augmented Analytics</Link>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
      <div icon="fa fa-building-o" label="Business Overview">
        <ul>
          <li key={"TotalSales"}>
            <Link to="/TotalSales">Total Sales / Payment Summary</Link>
          </li>
          <li key={"DSO"}>
            <Link to="/DSO">Day Sales Outstanding by Payer</Link>
          </li>
          <li key={"TPAR"}>
            <Link to="#">Third Party Accounts Receivable</Link>
          </li>
        </ul>
      </div>
      <div icon="fa fa-hourglass-end" label="Reconciliation and Aging Manager">
        <ul>
          <li key={"RxLookup"}>
            <Link to="/RxLookup">Rx Lookup</Link>
          </li>
          <li key={"Aging"}>
            <Link to="/Aging">Aging (Outstanding) Payments</Link>
          </li>
          <li key={"PaymentExceptions"}>
            <Link to="/PaymentExceptions">Payment Exceptions</Link>
          </li>
        </ul>
      </div>
      <div icon="fa fa-paper-plane-o" label="Remittance Administration">
        <ul>
          <li key={"RemitLookUp"}>
            <Link to="/RemitLookUp">Remittance Management</Link>
          </li>
          <li key={"MissingRemittances"}>
            <Link to="/MissingRemittances">Report a Missing Remittance</Link>
          </li>
          <li key={"835RemittanceExporting"}>
            <Link to="/835RemittanceExporting">835 Remittance Exporting</Link>
          </li>
          <li key={"ManualRemittance"}>
            <Link to="/ManualRemittance">Manual 835 Remittance Creation</Link>
          </li>
        </ul>
      </div>
      <div icon="fa fa-binoculars" label="Fee and Adjustment Tracker">
        <ul>
          <li key={"FeeAdjustment"}>
            <Link to="/FeeAdjustment">Fee and Adjustment Research</Link>
          </li>
          <li key={"CopayAdjustment"}>
            <Link to="/CopayAdjustment">Copay Adjustment Manager</Link>
          </li>
        </ul>
      </div>
      <div icon="fa fa-download" label="Report Queue">
        <ul>
          <li key={"ExcelExportDownloads"}>
            <Link to="/ExcelExportDownloads">Report Queue</Link>
          </li>
        </ul>
      </div>

      {userContactType == UserTypes.UCT ? (
        <div icon="fa fa-user" label="Admin Operations">
          <ul>
            <li key={"AdminExportBatchQueueMaintenance"}>
              <Link to="/AdminExportBatchQueueMaintenance">
                835 Export Batch Queue Maintenance
              </Link>
            </li>
            <li key={"AdminMissingRemittance"}>
              <Link to="/AdminMissingRemittance">Missing Remittance</Link>
            </li>
           {/*  <li key={"AdminPaymentMatching"}>
              <Link to="#">Payment Matching</Link>
            </li> */}
            <li key={"AdminRemittanceLookup"}>
              <Link to="/AdminRemittanceLookup"> Remittance Lookup</Link>
            </li>
            <li key={"AdminViewRestoreDeleted835"}>
              <Link to="/AdminViewRestoreDeleted835">
                View/Restore Deleted 835
              </Link>
            </li>
            <li key={"AdminRxLookup"}>
              <Link to="/AdminRxLookup">Rx Lookup</Link>
            </li>
          </ul>
        </div>
      ) : null}
    </Accordion>
  );
}
