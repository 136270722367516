function IsNullOrWhitespace(input) {
  return !input || input.trim().length < 1;
}

function HasSpecialCharacters(val) {
  var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/);
  return pattern.test(val);
}

function ProperStringValue(input) {
  // If the value is null or undefined, return an empty string
  if (input === null || input === undefined) {
    return "";
  }

  // Replace underscores with spaces, convert to Proper Case, then replace spaces back with underscores
  return input
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .replace(/ /g, "_"); // Replace spaces back with underscores
}

export { IsNullOrWhitespace, HasSpecialCharacters };
