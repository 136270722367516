import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { SharedFormRow, SharedFormColumn } from "../../layout/SharedFormElements/SharedFormRowAndColumns";
import KendoDropdown from "../../form/KendoDropdown";
import FormValidation from "../../form/validation/FormValidation";
import { UserContext } from "../../context/UserContext";
import { today } from "@progress/kendo-react-dateinputs";

export default function AdminMissingRemittanceForm(props) {
    const { handleFormSubmit } = props;

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [selectedParentCompany, setSelectedParentCompany] = useState("");
    const [selectedPayer, setSelectedPayer] = useState("");
    const [checkNumber, setCheckNumber] = useState("");
    const [mpid, setMpid] = useState("");
    const [selectedLocation, setSelectedLocation] = useState([]);

    const userContext = useContext(UserContext);
    const parentCompanyOrganizations = userContext.organizations.filter(org => org.type === "ParentCompany");
    const [payerDataSource, setPayerDataSource] = useState([]);
    const [locationFormatedDataSource, setLocationFormatedDataSource] = useState(
        []
    );

    const location = useLocation();

    useEffect(() => {
        getPayer();
    }, []);
    useEffect(() => {
        getUserLocation();
    }, [selectedParentCompany, userContext.isUnrestrictedEraPath]);

    function submitForm() {
        let parameters = {
            toDate: toDate,
            fromDate: fromDate,
            checkNumber: checkNumber,
            selectedPayer: selectedPayer,
            selectedParentCompany: selectedParentCompany,
            selectedLocation: selectedLocation,
            mpid: mpid,
        };
        let validationIssueMessage = ValidationMessage();

        if (validationIssueMessage) {
            alert(validationIssueMessage);
        } else {
            handleFormSubmit(parameters);
        }
    }

    function ValidationMessage() {
        if (checkNumber == "") {
            if (fromDate == "" || toDate == "") {
                return "Please enter start and end date.";
            }
            var startDateval = new Date(fromDate);
            var endDateval = new Date(toDate);
            if (startDateval > endDateval) {
                return "Start date should not greater than end date.";
            }

            var differentTime = endDateval.getTime() - startDateval.getTime();
            var differentDays = differentTime / (1000 * 60 * 60 * 24);
            if (differentDays > (365 * 2)) {
                return "Date range should not be more than 2 years.";
            }
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        submitForm();
    }

    function clearform() {
        setFromDate("");
        setToDate("");
        setSelectedParentCompany("");
        setSelectedPayer("");
        setCheckNumber("");
        setMpid("");
        setSelectedLocation([]);
    }

    async function getPayer() {
        setPayerDataSource([]);
        let parameters = {
            email: userContext.email
        };
        let accessToken = props.passedProps.auth.getAccessToken();
        let currentRoute = location.pathname;
        let header = {
            Authorization: `Bearer ${accessToken}`
        };

        let payerResponse = await axios.get(
            `api/UserProgram/GetAllPayers`,
            {
                params: parameters,
                headers: header,
            }
        );

        let PayerList = Object.keys(payerResponse.data).map((key) => {
            return {
                label: payerResponse.data[key],
                value: key,
            };
        });

        let sortedPayers = PayerList.sort((p1, p2) =>
            p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0
        );

        setPayerDataSource(sortedPayers);

    }
    async function getUserLocation() {
        setLocationFormatedDataSource([])
        if (selectedParentCompany) {
            if (selectedParentCompany.value != undefined) {
                let allLocationsParameters = {
                    email: userContext.email,
                    selectedItem: selectedParentCompany.value,
                    organizationType: 'ParentCompany',
                    programSubscriptions: 'Rec2.0'
                };
                let accessToken = props.passedProps.auth.getAccessToken();
                let allLocationsResponse = await axios.get(
                    `api/UserProgram/GetUserLocations`,
                    {
                        params: allLocationsParameters,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            RequestSrc: '/',
                        },
                    }
                );
                if (Object.keys(allLocationsResponse.data).length > 0) {
                    let allLocations = formatDictionaryToArray(allLocationsResponse.data);
                    setLocationFormatedDataSource(allLocations);
                }
                else {
                    setLocationFormatedDataSource([{
                        label: "",
                        value: "",
                    }]);
                }
            }
        }
    }
    function formatDictionaryToArray(data) {
        return Object.keys(data).map((key) => {
            var ncpdptext = data[key];
            var matchPattern = /\((.*)\)/i;
            ncpdptext = ncpdptext.match(matchPattern)[1];

            return {
                label: ncpdptext,
                value: ncpdptext,
            };
        });
    }
    function resetOnClick() {
        clearform();
        props.handleFormReset();
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SharedFormRow>
                <SharedFormColumn>
                    <Form.Label>Parent Company</Form.Label>

                    <KendoDropdown
                        dataSource={parentCompanyOrganizations || []}
                        textField="labelNoSuffix"
                        idField="value"
                        value={selectedParentCompany}
                        sortBy="asc"
                        onChange={(e) => {
                            setSelectedParentCompany(e);
                        }}
                    />
                </SharedFormColumn>
                <SharedFormColumn>
                    <Form.Label>Payer</Form.Label>

                    <KendoDropdown
                        dataSource={payerDataSource || []}
                        textField="label"
                        idField="value"
                        value={selectedPayer}
                        onChange={(e) => {
                            setSelectedPayer(e);
                        }}
                    />
                </SharedFormColumn>
                <SharedFormColumn>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                        name="fromDate"
                        as={DatePicker}
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                    />
                </SharedFormColumn>
                <SharedFormColumn>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                        name="toDate"
                        as={DatePicker}
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                    />
                </SharedFormColumn>
            </SharedFormRow>
            <SharedFormRow>
                <SharedFormColumn>
                    <Form.Label>Location (NCPDP)</Form.Label>

                    <KendoDropdown
                        dataSource={locationFormatedDataSource || []}
                        textField="label"
                        idField="value"
                        value={selectedLocation}
                        onChange={(e) => {
                            setSelectedLocation(e);
                        }}
                    />
                </SharedFormColumn>
                <SharedFormColumn>
                    <Form.Label>MPID</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        onChange={(e) => {
                            setMpid(e.target.value);
                        }}
                        value={mpid}
                    />
                </SharedFormColumn>
                <SharedFormColumn>
                    <Form.Label>Check Number</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        onChange={(e) => {
                            setCheckNumber(e.target.value);
                        }}
                        value={checkNumber}
                    />
                </SharedFormColumn>
                <SharedFormColumn>
                    <Button className="inline-show-result-button" size="sm" type="submit">
                        Show Results
                    </Button>
                </SharedFormColumn>
                <SharedFormColumn>
                    <Button className="inline-show-result-button" size="sm" type="button" onClick={resetOnClick}>
                        Reset
                    </Button>
                </SharedFormColumn>
            </SharedFormRow>
        </Form>
    );
}