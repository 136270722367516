import Accordion from "../../Accordion";
import React, { useState } from "react";
import ErrorHandler from "../../core/ErrorHandler";

import { Row, Col } from "react-bootstrap";
import RxLookupForm from "./AdminRxLookupForm";

import AdminRxLookupDetailPopup from "./AdminRxLookupDetailPopup";

import { GridColumn } from "@progress/kendo-react-grid";

import SharedMainSelectableServerGrid from "../../grid/Kendo/SharedMainSelectableServerGrid";

import {
  DefaultColumnWidth,
  DefaultDateColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";

export default function AdminRxLookup() {
  const [error, setError] = useState({});

  const [setExpanded] = useState(false);
  const [detailError, setDetailError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState(null);

  const [selectedRxNumber, setSelectedRxNumber] = useState(null);
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);

  const _container = React.useRef();
  let defaultColumnWidth = DefaultColumnWidth();

  const [showDetailsModal, setShowDetailsModal] = useState(false);

  function handleFormSubmit(formInput) {
    // setIsLoading(true);

    setSubmittedFormData(formInput);
  }

  const sendAccordionParent = (props) => {
    setExpanded(props);
  };

  const GridViewDetailActionCell = (props) => {
    async function editClick() {
      //var thisDataItem = JSON.stringify(props.dataItem);

      //alert(thisDataItem);
      // alert(props.dataItem["rxNumber"]);

      setShowDetailsModal(true);

      setSelectedRxNumber(props.dataItem["rxNumber"]);
      setSelectedClaimId(props.dataItem["claimId"]);
      setSelectedPaymentId(props.dataItem["paymentId"]);
    }

    return (
      <td className="noWrap" {...props.style}>
        <a
          onClick={editClick}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#015CAB",
          }}
        >
          View Details
        </a>
      </td>
    );
  };

  return (
    <React.Fragment>
      <div className="feature">
        <ErrorHandler
          error={error}
          onClose={() => {
            setError({});
          }}
        />
        <Accordion
          defaultExpanded
          label="Search & Filter"
          sendAccordionParentCallback={sendAccordionParent}
        >
          <Row>
            <Col md="auto" className="d-flex flex-column higher-zindex-filters">
              <RxLookupForm handleFormSubmit={handleFormSubmit}></RxLookupForm>
            </Col>
          </Row>
        </Accordion>

        <div ref={_container}>
          <Row>
            <Col className="tight-grid multi-line-filter">
              <br />

              <SharedMainSelectableServerGrid
                exportFileNamePrefix={"CurrentAgingDetails"}
                apiGetEndpoint={"api/AdminRxLookup/GetRxLookupResult"}
                apiSubmittedFormData={submittedFormData}
                gridErrorHandlerCallback={setDetailError}
              >
                <GridColumn
                  field=""
                  title="Action"
                  filterable={false}
                  sortable={false}
                  groupable={false}
                  cells={{ data: GridViewDetailActionCell }}
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  field="rxNumber"
                  title="Rx Number"
                  width={defaultColumnWidth}
                  filterable={false}
                />
                <GridColumn
                  field="ncpdp"
                  title="NCPDP"
                  width={defaultColumnWidth}
                  filterable={false}
                />

                <GridColumn
                  field="bin"
                  title="Bin"
                  width={defaultColumnWidth}
                  filterable={false}
                />

                <GridColumn
                  field="ndc"
                  title="Ndc"
                  width={defaultColumnWidth}
                  filterable={false}
                />

                <GridColumn
                  field="serviceDate"
                  title="ServiceDate"
                  format="{0:d}"
                  width={DefaultDateColumnWidth()}
                  filterable={false}
                />

                <GridColumn
                  field="transactionDate"
                  title="TransactionDate"
                  format="{0:d}"
                  width={DefaultDateColumnWidth()}
                  filterable={false}
                />

                <GridColumn
                  field="payerName"
                  title="Payer"
                  width={defaultColumnWidth}
                  filterable={false}
                />

                <GridColumn
                  field="adjAmount"
                  title="Adjudicated Amount"
                  format="{0:c}"
                  width={defaultColumnWidth}
                  filterable={false}
                />

                <GridColumn
                  field="adjAmount"
                  title="Paid Amount"
                  format="{0:c}"
                  width={defaultColumnWidth}
                  filterable={false}
                />

                <GridColumn
                  field="ignoreReason"
                  title="Ignore Reason"
                  width={defaultColumnWidth}
                  filterable={false}
                />
              </SharedMainSelectableServerGrid>
            </Col>
          </Row>
        </div>

        <AdminRxLookupDetailPopup
          selectedRxNumber={selectedRxNumber}
          selectedClaimId={selectedClaimId}
          selectedPaymentId={selectedPaymentId}
          show={showDetailsModal}
          onClose={() => {
            setShowDetailsModal(false);
          }}
        ></AdminRxLookupDetailPopup>
      </div>
    </React.Fragment>
  );
}
