import React from "react";
import video from './Remit Admin Tutorial.mp4';

export default function TutorialVideo(props) {

    return (
        <div>
            <video src={video} width="750" height="500" controls>
            </video>
        </div>
    );
}