import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

import { JsonToArray } from "../../../helpers/JsonHelpers";

//create a function to return a display item, which goes into every table cell,  it should be a title and a value that goes horizontally

function DisplayItem(props) {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div style={{ fontWeight: "bold", marginRight: "10px" }}>
        {props.title}:
      </div>
      <div>{props.value}</div>
    </div>
  );
}

export default function AdminRxLookupDetailPopupDataGrid(props) {
  //convert the json data to an array of objects

  var { data, columnCount } = props;

  var dataArray = JsonToArray(data);

  //if columns are not specified, then default to 4 columns

  if (!columnCount) columnCount = 4;

  return (
    <div style={{ width: "100%" }}>
      <GridLayout
        rows={[{ height: "auto" }, { height: "auto" }, { height: "auto" }]}
        cols={[{ width: "auto" }, { width: "auto" }, { width: "auto" }]}
        gap={{ rows: 5, cols: 5 }}
        align={{ horizontal: "stretch", vertical: "stretch" }}
      >
        {
          //ADD A row for every 4 items  and then add a cell of Display Item for every key and value

          dataArray &&
            dataArray.map((item, index) => {
              //there are 4 columns
              let currentrow = Math.floor(index / columnCount) + 1;

              //now calculate the current column index for the item in the currentRow variable, which is the remainder of the index divided by 4
              let currentCol = index % columnCount;

              return (
                <GridLayoutItem row={currentrow} col={currentCol} key={index}>
                  <DisplayItem title={item.key} value={item.value} />
                </GridLayoutItem>
              );
            })
        }
      </GridLayout>
    </div>
  );
}
